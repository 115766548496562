import * as React from 'react';
import BusinessSolutions from './components/home/business-solutions';
import OurServices from './components/home/our-services';
import Layout from '../components/layout';
import Seo from '../components/seo';
import Blog from './components/home/blog';
import Header from './components/home/header';
import AboutUs from './components/home/about-us';
import CarousalComponent from '../components/carousal-component';
import { ClientData, PartnersData } from '../data/carousal-db';

export default function IndexPage() {
  return (
    <Layout>
      <Seo title="Home" />
      <section>
        <Header />
        <BusinessSolutions />
        <AboutUs />
        <OurServices />
        <Blog />
        <CarousalComponent Data={PartnersData} />
        <CarousalComponent Data={ClientData} />
      </section>
    </Layout>
  );
}
