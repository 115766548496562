import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { solutionsData } from '../../../data/db';

export default function BusinessSolutions() {
  return (
    <section className="business-solutions line">
      <Container>
        <div className="infenox-heading mx-auto">
          <h2 className="fs-2">{solutionsData[0].name}</h2>
          <span className="infenox-text-highlight" />
        </div>
        <Row>
          {solutionsData[0].data.map(({
            id, icon, heading, desc,
          }) => (
            <Col className="col-12 col-sm-6 col-md-4 pt-5" key={id}>
              <Row>
                <Col className="text-end col-4"><img src={icon} alt="Solutions Icon" /></Col>
                <Col className="col-8">
                  <h4 className="fs-5">{heading}</h4>
                  <p className="text fs-6">{desc}</p>
                </Col>
              </Row>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
}
